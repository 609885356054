import { LiquidButton } from '@/components/LiquidButton';
import { AVAILABLE_THEMES, AVAILABLE_THEMES_TYPE } from '@/styles/constants';

export const LiquidButtonElement = ({
  text = '',
  width,
  ctaColor = '#EFDBB2',
  textColor = '#000',
  hasTheme,
  themeName,
}: {
  text: string;
  width: number;
  ctaColor?: string;
  textColor?: string;
  hasTheme?: boolean;
  themeName?: AVAILABLE_THEMES_TYPE;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return <LiquidButton variant="primary" text={text} className="mt-5" />;
  }

  return (
    <LiquidButton
      text={text}
      height={50}
      width={width}
      color={ctaColor}
      textColor={textColor}
      useCustomColorsAsImportant={hasTheme}
    />
  );
};
