import { ParagraphText } from '@/components/ParagraphText';
import { SuperText } from '../styles';
import { AVAILABLE_THEMES, AVAILABLE_THEMES_TYPE } from '@/styles/constants';

export const SuperTextElement = ({
  text,
  themeName,
  dataTest,
}: {
  text: string;
  themeName?: AVAILABLE_THEMES_TYPE;
  dataTest: string;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <ParagraphText data-test={dataTest} className="uppercase" type="xs">
        {text}
      </ParagraphText>
    );
  }

  return (
    <SuperText data-test={dataTest} tag="p" type="lead">
      {text}
    </SuperText>
  );
};
